let poetrys = [
    // 李商隐
    '怅卧新春白袷衣，白门寥落意多违。<br> &nbsp;红楼隔雨相望冷，珠箔飘灯独自归。',
    '紫泉宫殿锁烟霞，欲取芜城作帝家。<br> &nbsp;玉玺不缘归日角，锦帆应是到天涯。',
    // 白居易
    '已讶衾枕冷，复见窗户明。<br> &nbsp;夜深知雪重，时闻折竹声。',
    '绿蚁新醅酒，红泥小火炉。<br> &nbsp;晚来天欲雪，能饮一杯无？',
    '人间四月芳菲尽，山寺桃花始盛开。<br> &nbsp;长恨春归无觅处，不知转入此中来。',
    '丝纶阁下文书静，钟鼓楼中刻漏长。<br> &nbsp;独坐黄昏谁是伴，紫薇花对紫微郎。',
    '好风胧月清明夜，碧砌红轩刺史家。<br> &nbsp;独绕回廊行复歇，遥听弦管暗看花。',
    '风香露重梨花湿，草舍无灯愁未入。<br> &nbsp;南邻北里歌吹时，独倚柴门月中立。',
    // 温庭筠
    '香灯伴残梦，楚国在天涯。<br> &nbsp;月落子规歇，满庭山杏花。',
    '冰簟银床梦不成，碧天如水夜云轻。<br> &nbsp;雁声远过潇湘去，十二楼中月自明。',
    // 宋之问
    '暗芳足幽气，惊栖多众音。<br> &nbsp;高兴南山曲，长谣横素琴。',
    // 王绩
    '浮生知几日，无状逐空名。<br> &nbsp;不如多酿酒，时向竹林倾。',
    '故乡行云是，虚室坐间同。<br> &nbsp;日落西山暮，方知天下空。',
    '阮籍醒时少，陶潜醉日多。<br> &nbsp;百年何足度，乘兴且长歌。',
    '野觞浮郑酌，山酒漉陶巾。<br> &nbsp;但令千日醉，何惜两三春。',
    // 孟浩然
    '移舟泊烟渚，日暮客愁新。<br> &nbsp;野旷天低树，江清月近人。',
    // 岑参
    '强欲登高去，无人送酒来。<br> &nbsp;遥怜故园菊，应傍战场开。',
    '走马西来欲到天，辞家见月两回圆。<br> &nbsp;今夜不知何处宿，平沙万里绝人烟。',
    // 王维
    '远看山有色，近听水无声。<br> &nbsp;春去花还在，人来鸟不惊。',
    '人闲桂花落，夜静春山空。<br> &nbsp;月出惊山鸟，时鸣春涧中。',
    '独坐幽篁里，弹琴复长啸。<br> &nbsp;深林人不知，明月来相照。',
    '荆溪白石出，天寒红叶稀。<br> &nbsp;山路元无雨，空翠湿人衣。',
    '清浅白石滩，绿蒲向堪把。<br> &nbsp;家住水东西，浣纱明月下。',
    '可怜盘石临泉水，复有垂杨拂酒杯。<br> &nbsp;若道春风不解意，何因吹送落花来。',
    // 张九龄
    '松叶堪为酒，春来酿几多。<br> &nbsp;不辞山路远，踏雪也相过。',
    // 柳宗元
    '千山鸟飞绝，万径人踪灭。<br> &nbsp;孤舟蓑笠翁，独钓寒江雪。',
    '江雨初晴思远步，日西独向愚溪渡。<br> &nbsp;渡头水落村径成，撩乱浮槎在高树。',
    // 刘长卿
    '苍苍竹林寺，杳杳钟声晚。<br> &nbsp;荷笠带斜阳，青山独归远。',
    // 吕洞宾
    '草铺横野六七里，笛弄晚风三四声。<br> &nbsp;归来饱饭黄昏后，不脱蓑衣卧月明。',
    // 吴均
    '山际见来烟，竹中窥落日。<br> &nbsp;鸟向檐上飞，云从窗里出。',
    // 徐玑
    '水满田畴稻叶齐，日光穿树晓烟低。<br> &nbsp;黄莺也爱新凉好，飞过青山影里啼。',
    // 刘禹锡
    '杨柳青青江水平，闻郎江上踏歌声。<br> &nbsp;东边日出西边雨，道是无晴却有晴。',
    // 纳兰性德
    '谁念西风独自凉，萧萧黄叶闭疏窗，沉思往事立残阳。<br> &nbsp;被酒莫惊春睡重，赌书消得泼茶香，当时只道是寻常。',
    // 赵师秀
    '黄梅时节家家雨，青草池塘处处蛙。<br> &nbsp;有约不来过夜半，闲敲棋子落灯花。',
    // 唐寅
    '我画蓝江水悠悠，爱晚亭上枫叶愁。<br> &nbsp;秋月融融照佛寺，香烟袅袅绕经楼。',
]

export default poetrys;
