<template>
<div id="headerBlock">
  <div class='console-container'>
    <span id='text'></span>
    <div class='console-underscore' id='console'>&#95;</div>
  </div>

</div>
</template>

<script>
import poetrys from '../assets/poetry.js';

export default {
  name: "myHeader",
  data(){
    return {
      content:String,
    }
  },
  methods: {
    cursorTypeEffect: function (){
      let visible = true;
      let con = document.getElementById('console');
      window.setInterval(function() {
        if (visible === true) {
          con.className = 'console-underscore hidden';
          visible = false;
        } else {
          con.className = 'console-underscore';
          visible = true;
        }}, 400)
    },
    textTypeEffect: function (id){
      let word = poetrys[Math.round(Math.random() * poetrys.length - 1)];  // 随机选取
      let letterCount = 1;
      let x = 1;
      let waiting = false;
      let target = document.getElementById(id);
      target.setAttribute('style', 'color:' + ['#fff']);
      window.setInterval(function() {
        if (letterCount === 0 && waiting === false) {
          word = poetrys[Math.round(Math.random() * poetrys.length - 1)];  // 随机更新
          waiting = true;
          target.innerHTML = word.substring(0, letterCount)
          window.setTimeout(function() {
            x = 1;
            letterCount += x;
            waiting = false;
          }, 1000)
        } else if (letterCount === word.length + 1 && waiting === false) {
          waiting = true;
          window.setTimeout(function() {
            x = -1;
            letterCount += x;
            waiting = false;
          }, 11000)
        } else if (waiting === false) {
          target.innerHTML = word.substring(0, letterCount)
          if(word[letterCount] === '<' || word[letterCount] === '>'){letterCount += 2 * x;}
          if(word[letterCount] === '&' || word[letterCount] === ';'){letterCount += 6 * x;}
          else {letterCount += x;}
        }
      }, 150)
    },
  },
  mounted() {
    this.cursorTypeEffect();
    this.textTypeEffect('text');
  }
}
</script>

<style scoped>
#headerBlock {
  width: 100%;
  margin: 5px auto;
}
.hidden {
  opacity:0;
}
.console-container {
  font-size:1em;
  height: 60px;
  text-align:center;
  display:block;
  color:white;
  margin:auto;
}
.console-underscore {
  display:inline-block;
  position:relative;
  top:-0.14em;
}
@media screen and (max-width: 800px) {
  .console-container {
    font-size:0.8em;
  }
}
</style>