<template>
  <button class="pulse">
    {{text}}
  </button>
</template>

<script>
export default {
  name: "pageButton",
  props:{
    text:String,
  },
}
</script>

<style scoped>
button {
    width: 100px;
    height: 40px;
  background: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  margin: 0.5em;
  padding-top: 10px;
  padding-bottom: 10px;
}
button {
  color: var(--color);
  transition: 0.25s;
}
button:hover, button:focus {
  border-color: var(--hover);
  color: #fff;
}
.pulse {
  --color: #ef6eae;
  --hover: #ef8f6e;
}
.pulse:hover,
.pulse:focus {
  -webkit-animation: pulse 1s;
  animation: pulse 1s;
  box-shadow: 0 0 0 2em transparent;
}
@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}
</style>