<template>
  <footer id="footer">
    <div class="footer-text" id="Footer-TimeBlock">小站运行起点: 2021-05-01 | 博客总数:{{postCounts}}</div>
    <div class="footer-text" id="Footer-NameBlock">雾雨霜星 | 雾间觉雨,霜空望星</div>
    <div class="footer-text" id="Footer-HtmlSecurity">
      <img src="@/assets/HtmlSecurityIcon.png" alt="..."/><a id="footer-link" target="_blank" href="https://beian.miit.gov.cn/">粤ICP备 2021052356号-1</a>
    </div>
  </footer>
</template>

<script>
let postCounts;
export default {
  name: "myFooter",
  props:{
    postCounts,
  },
}
</script>

<style scoped>
#footer{
  width: 100%;
  height: 120px;
  padding: 15px 0 5px 0;
  background-color: rgba(255,255,255,0);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
#footer-link{
  text-decoration:none;
}
.footer-text{
  text-align: center;
  padding: 2px 0 2px 0;
}
@media screen and (max-width: 800px) {
  #footer{
    font-size: 0.8rem;
  }
  .footer-text{
  }
}
@media screen and (max-width: 800px) {
  #footer{
    font-size: 0.6rem;
  }
}
</style>